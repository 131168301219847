<template>
  <div id="homeProductTraceComponent" style="padding-top: 20px;">
    <div style="display: flex; width: 100%;" v-for="(item, index) in totalStageNum" :key="index">
      <div style="margin-right: 8px;text-align: -webkit-center;">
        <div style="margin-bottom: 4px;"
          />
        <div class="homeProductTraceIcon" />
        <div style="height: calc(100% - 5px - 6px); width: 0; border: #c9e5c8 1px dashed; margin-top: 4px;"
          :style="{ border: index === (totalStageNum - 1) ? `#f5f6f8 1px dashed` : `#c9e5c8 1px dashed` }" />
      </div>

      <!-- 出品企业 -->
      <div class="box" v-if="first && index === 0" @click="onClickFirst">
        <div style="display: flex; font-size: 14px; color: #222222; font-weight: bold;">
          <div class="title" style="flex: 1;">
            <span>{{ '出品企业' }}</span>
          </div>
<!--          <div class="box-detail">-->
<!--            {{ '了解更多' }}-->
<!--          <img src="../assets/images/right-arrow.png" style="width: 14px; height: 14px; margin-top: 1px;" /></div>-->
        </div>

        <div class="box-info">
          <div style="flex: 1;margin-top:8px;">
            <div style="display: flex; color: #A1A1A1;">
              企业名称:
              <div style="flex: 1; color: #222222; margin-left: 8px;">{{ first.name }}</div>
            </div>
            <div style="display: flex; color: #A1A1A1; margin: 14px 0;">
              <!--              认证资质:-->
              <div style="display:flex; flex: 1; color: #222222; align-items: center;">
                <div v-for="(item, index) in first.trustList" :key="index" style="display: flex;"
                  :style="{ marginLeft: index !== 0 ? `10px` : `0` }">
                  <img src="../assets/images/icon-earnest.png" style="width: 14px; height: 14px; margin-top: 1px;" />
                  <div style="line-height: 14px; font-size: 12px; margin-left: 2px;">{{ item.name }}</div>
                </div>
              </div>
            </div>

            <!--            <div style="display: flex; color: #A1A1A1; margin-top: 4px;">-->
            <!--              认证资质:-->
            <!--              <div style="color: #222222; align-items: center;">-->
            <!--                <div v-for="(item, index) in first.trustList" :key="index" style="display: flex; margin-left: 8px;" :style="{ marginTop: index !== 0 ? `2px` : `0`}">-->
            <!--                  <img src="../assets/images/icon_trust.png" style="width: 16px; height: 16px; margin-top: 1px;"/>-->
            <!--                  <div style="line-height: 16px; font-size: 12px;">{{ item.name }}</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

          </div>
        </div>

      </div>

      <!-- 下游采购 -->
      <div class="box" v-if="last && index === totalStageNum - 1">
        <div style="display: flex; font-size: 14px; color: #222222; font-weight: bold;">
          <div class="title" style="flex: 1;">
            <span>{{ '下游采购' }}</span>
          </div>

        </div>

        <div class="box-info">
          <div style="display: flex; margin-top: 10px;">
          <div style="flex: 1;">
            <div style="display: flex; color: #A1A1A1;">
              采购商家:
              <div style="flex: 1; color: #222222; margin-left: 8px;">{{ last.name }}</div>
            </div>
            <!--            <div style="display: flex; color: #A1A1A1; margin-top: 4px;">-->
            <!--              认证资质:-->
            <!--              <div style="display:flex; flex: 1; color: #222222; margin-left: 8px; align-items: center;">-->
            <!--                <div v-for="(item, index) in trustList" :key="index" style="display: flex;" :style="{ marginLeft: index !== 0 ? `10px` : `0`}">-->
            <!--                  <img src="../assets/images/icon_trust.png" style="width: 18px; height: 18px;"/>-->
            <!--                  <div style="line-height: 18px;">{{ '认真' }}</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
        </div>
      </div>

      <!-- 养殖阶段 -->
      <div class="box" v-if="!(index === 0) || (!last && index === totalStageNum - 1)">
        <div style="display: flex; font-size: 14px; color: #222222; font-weight: bold;justify-content: space-between;">
          <div class="title">
            <span>
              {{
              stageList[index - 1].stage === 0 ? '鸡苗阶段' : (stageList[index - 1].stage === 1 ? '坡养阶段' : stageList[index -
                1].stage === 2 ? '育肥阶段' : '')
            }}
            </span>
          </div>
          <div
            style="text-align: right; margin-right: 8px; color: #666666; font-weight: normal; font-size: 12px; line-height: 14px; align-self: center;">
            {{ stageList[index - 1].cycle }}
          </div>
        </div>
        <div class="box-info">
          <div style="display: flex; margin-top: 8px;">
          <div style="flex: 1;">
            <div style="display: flex; color: #A1A1A1;">
              养殖天数
              <div style="flex: 1; color: #222222; margin: auto 0 auto 8px;">{{ stageList[index - 1].cycleDay }}</div>
            </div>
            <div style="display: flex; color: #A1A1A1; margin-top: 4px;" v-if="stageList[index - 1].step > 0">
              行走步数
              <div style="flex: 1; color: #222222; margin: auto 0 auto 8px;">{{ stageList[index - 1].step }}</div>
            </div>
            <!--            <div style="display: flex; color: #A1A1A1; margin-top: 4px;">-->
            <!--              起止时间:-->
            <!--              <div style="flex: 1; color: #222222; margin: auto 0 auto 8px;">{{ stageList[index - 1].cycle }}</div>-->
            <!--            </div>-->
            <div style="display: flex; color: #A1A1A1; margin-top: 8px;">
              养
              <div style="width: 7px" />
              殖
              <div style="width: 7px" />
              户
              <div style="flex: 1; color: #222222; margin: auto 0 auto 8px;">{{ stageList[index - 1].breedingFarmer ? stageList[index - 1].breedingFarmer : '个体户' }}</div>
            </div>
            <div style="display: flex; color: #A1A1A1; margin-top: 8px;">
              养
              <div style="width: 7px" />
              殖
              <div style="width: 7px" />
              场
              <div style="color: #34AC40; margin: auto 0 auto 8px;font-size: 13px;"
                @click="onClickFarm(stageList[index - 1])">
                {{ stageList[index - 1].farmManagerName ? stageList[index - 1].farmManagerName : '个体户' }}
              </div>
              <div style="display: flex; align-items: center; justify-content: center;" v-if="stageList[index - 1].farmManagerName">
                <img src="../assets/images/green-right-arrow.png" style="height: 15px;"/>
              </div>
            </div>
            <div style="display: flex; color: #A1A1A1; margin:8px 0 10px;">
              圈
              <div style="width: 7px" />
              舍
              <div style="width: 7px" />
              码
              <div style="flex: 1; color: #222222; margin: auto 0 auto 8px;">{{
                stageList[index - 1].farmManagerCircleHouse ? stageList[index - 1].farmManagerCircleHouse : '无'
              }}
              </div>
            </div>
          </div>
          <div v-if="!stageList[index - 1].thumbnailImageUrl" style="margin-left: 8px;">
            <van-image
            :src="index === 1 ? 'https://asset.fsytss.com/trace/wcj/default_state_1.png' : (index === 2 ? 'https://asset.fsytss.com/trace/wcj/default_state_2.png' : 'https://asset.fsytss.com/trace/wcj/default_state_3.png')"
              alt="" fit="cover" style="width: 80px; height: 80px;" />
          </div>
          <van-image :src="stageList[index - 1].thumbnailImageUrl" alt="" style="width: 80px; height: 80px; margin-left: 8px;"
            v-if="stageList[index - 1].thumbnailImageUrl" />
        </div>
        <div class="info-bottom">
          <div style="display: flex; margin-top: 8px;" v-if="stageList[index - 1].stage === 2">
          <div style="flex: 1;border-radius: 20px; background: #ecf6ec; color: #34AC40; text-align: center;padding: 8px; margin-bottom: 20px;"
            @click="onClickFeedingRecord(stageList[index - 1])">
            饲养记录
          </div>

          <div style="flex: 1;border-radius: 20px; background: #ecf6ec; color: #34AC40; text-align: center;padding: 8px; margin-left: 16px; margin-bottom: 20px;"
            @click="onClickVaccinumRecord(stageList[index - 1])">
            防疫记录
          </div>

        </div>
        </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { getDiffDay } from "../utils/util";
import Vue from 'vue'
import { Image as VanImage} from "vant";
Vue.use(VanImage)
export default {
  name: "HomeProductTraceComponent",
  props: [
    'totalStageNum', 'first', 'last', 'stageList'
  ],
  data() {
    return {}
  },
  methods: {
    onClickFirst() {
      this.$parent.onClickShowBusiness(this.first.trustList);
    },
    onClickFeedingRecord(item) {
      this.$parent.onClickShowFeedingRecord(item.id);
    },
    onClickVaccinumRecord(item) {
      this.$parent.onClickShowVaccinumRecord(item.id);
    },
    onClickFarm(item) {
      if (!item.farmManagerId) {
        return;
      }
      this.$parent.onClickShowFarm(item.farmManagerId);
    },
  }
}
</script>

<style lang="less" scoped>
.homeProductTraceIcon {
  height: 15px;
  width: 15px;
  background: url(../assets/images/double-dot.png) no-repeat center / cover;
  margin-top: 10px;
}

.box {
  flex: 1;
  background: #fff;
  border-radius: 8px;
  margin: 4px 0;
  border-top: 2px solid #C2E6C5;
  margin: 0 0 16px;

  .title {

    span {
      display: inline-block;
      padding: 6px 20px 6px 8px;
      border-radius: 7px 0;
      position: relative;
      z-index: 1;
      overflow: hidden;
      color: #34AC40;

      &::after {
        content: "";
        background: #c9e5c8;
        display: inline-block;
        position: absolute;
        top: 0px;
        left: -3px;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: skewX(-10deg);
        overflow: hidden;
        border-radius: 0 0 7px 0;
      }
    }

  }

  .box-detail{
    padding: 6px 8px;
    font-size: 12px;
    color: #999999;
    display: flex;
    align-items: center;
    font-weight: initial;
  }

  .box-info{
    padding: 0 8px;
  }
  ::v-deep.van-image__img{
  border-radius: 5px ;
}

}

</style>