<template>
  <div id="ctBusinessInfoComponent" style="padding-top: 16px;">
    <van-grid :border="false" :column-num="3">
      <van-grid-item v-for="(item, index) in dataList" :key="index">
        <van-image :src="item.userImg ? item.userImg : ''" width="100%" height="64px" />
        <div style="font-size: 14px; line-height: 14px; color: #222222; margin-top: 8px;">{{ item.name }}</div>
        <div style="font-size: 14px; line-height: 14px; color: #222222; margin-top: 4px;">{{ getJob(item.job) }}</div>
      </van-grid-item>
    </van-grid>

  </div>
</template>

<script>
import Vue from "vue";
import {Grid, GridItem ,Image as VanImage} from "vant";
Vue.use(Grid).use(GridItem).use(VanImage)

export default {
  name: "CTBusinessInfoComponent",
  props: [
    'dataList'
  ],
  mounted(){
    console.log(123);
  },
  data() {
    return {
      data: {
        title: '-',
      }
    }
  },
  methods: {
    getJob(job) {
      switch (job) {
        case 'HEAD_CHEF':
            return '厨师长';
        case 'LEGAL_REPRESENTATIVE':
          return '法定代表人(负责人)';
        case 'STORE_MANAGER':
          return '门店经理';
        case 'PURCHASING_MANAGER':
          return '采购负责人';
        case 'LOBBY_MANAGER':
          return '大堂经理';
        case 'FULL_TIME_FOOD_SAFETY_MANAGEMENT_PERSONNEL':
          return '食品安全管理人员(专职)';
        case 'PART_TIME_FOOD_SAFETY_MANAGEMENT_PERSONNEL':
          return '食品安全管理人员(兼职)';
        case 'RAW_MATERIAL_PROCUREMENT_PERSONNEL':
          return '原料采购人员';
        case 'DIVIDED_MEAL_PERSONNEL':
          return '分餐人员';
        case 'SPECIALIZED_OPERATORS':
          return '专间操作人员';
        case 'TABLEWARE_SANITIZER_PERSONNEL':
          return '餐饮具消毒人员';
        case 'CATERING_SUPERVISOR_PERSONNEL':
          return '餐饮主管人员';
        case 'CHEF':
          return '厨师';
        case 'PRODUCTION_SUPERVISOR':
          return '出品主管';
        case 'AUXILIARY_TEAM_LEADER':
          return '辅工组长';
        case 'AUXILIARY_WORKER':
          return '辅工';
        case 'CLERK':
          return '文员';
        case 'OTHER':
          return '其他';
        case '':
          return '';
      }
      return '-';
    }
  }
}
</script>

<style scoped>

</style>